import React from 'react';
import SocialMedia from "./SocialMedia";

const WelcomeHero = () => {
  return (
    <div className="section1">
      <h2>Embrace the Spirit of unity in worship</h2>
      <p>
        Connect with our services online or join us in-person for life changing
        worship services
      </p>
      <div className="flex">
        <button className="red">Stream Live</button>
        <button className="blur">Learn more</button>
      </div>

      {/* social media section */}
      <SocialMedia />
    </div>
  );
}

export default WelcomeHero;