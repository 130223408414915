const data = [{
    "header": "About Us",
    "children": [{
        "route": "/meet-our-pastor",
        "title": "Meet our Pastor"
      },
      {
        "route": "/our-team",
        "title": "Our Team"
      }
    ]
  },
  {
    "header": "Get Involved",
    "children": [{
        "route": "/young-women",
        "title": "Young Women"
      },
      {
        "route": "/covenant-children",
        "title": "Covenant Children"
      }, {
        "route": "/youths",
        "title": "Youths"
      },
      {
        "route": "/christian-men",
        "title": "Christian Men"
      }, {
        "route": "/christian-women",
        "title": "Christian Women"
      }
    ]
  }, 
  {
    "header": "Media",
    "children": [{
        "route": "/sermons",
        "title": "Sermons"
      },
      {
        "route": "/gallery",
        "title": "Gallery"
      }
    ]
  }
]

export default data;