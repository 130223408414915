import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import data from "./navigation";
import "./Navbar.scss";
import Dropdown from "./Dropdown";
import images from "../assets/image";
import { FaBars, FaTimes } from "react-icons/fa"; // Import icons for the hamburger menu

const Navbar = () => {
  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleDropdownToggle = (index) => {
    setOpenDropdownIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen((prev) => !prev);
  };

  const renderDropdowns = () => {
    return data.map((item, index) => (
      <Dropdown
        key={index}
        isOpen={openDropdownIndex === index}
        onClick={() => handleDropdownToggle(index)}
        header={item.header}
        children={item.children}
      />
    ));
  };

  return (
    <nav className="navbar">
      <div className="logo">
        <NavLink exact to="/" className="navlink">
          <img src={images.logo} alt="logo" />
        </NavLink>
      </div>
      <div className={`links ${isMobileMenuOpen ? "active" : ""}`}>
        {renderDropdowns()}
        <NavLink exact to="/" className="navlink">
          Salvation Prayer
        </NavLink>
      </div>
      <div className="donate">
        <button>Donate</button>
      </div>
      <div className="hamburger" onClick={toggleMobileMenu}>
        {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
      </div>
    </nav>
  );
};

export default Navbar;
