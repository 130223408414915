import ChristianMen from './app/ChristianMen/ChristianMen';
import ChristianWomen from './app/ChristianWomen/ChristianWomen';
import CovenantChildren from './app/CovenantChildren/CovenantChildren';
import Gallery from './app/Gallery/Gallery';
import Home from './app/Home/Home';
import MeetourPastors from './app/MeetourPastors/MeetourPastors';
import OurTeams from './app/OurTeam/OurTeams';
import Sermons from './app/Sermons/Sermons';
import YoungWomen from './app/YoungWomen/YoungWomen';
import Youths from './app/Youths/Youths';
import { Routes, Route } from 'react-router-dom';

function App() {
  return (
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/christian-men" element={<ChristianMen />} />
        <Route path="/christian-women" element={<ChristianWomen />} />
        <Route path="/covenant-children" element={<CovenantChildren />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/meet-our-pastor" element={<MeetourPastors />} />
        <Route path="/our-team" element={<OurTeams />} />
        <Route path="/sermons" element={<Sermons />} />
        <Route path="/young-women" element={<YoungWomen />} />
        <Route path="/youths" element={<Youths/>} />
      </Routes>
  );
}

export default App;
