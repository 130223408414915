import React from 'react'; 
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faYoutube,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";

const SocialMedia = () => {
  return (
    <div className="socials">
      <a href="https://www.facebook.com/trem.baltimore.1/">
        <FontAwesomeIcon icon={faFacebook} size="2x" />
      </a>
      <a href="https://www.instagram.com/trembaltimore/">
        <FontAwesomeIcon icon={faInstagram} size="2x" />
      </a>
      <a href="https://www.youtube.com/channel/UC0Z9dYpbeypzKGLmS6_UilQ">
        <FontAwesomeIcon icon={faYoutube} size="2x" />
      </a>
      <a href="https://www.tiktok.com/@trembaltimore">
        <FontAwesomeIcon icon={faTiktok} size="2x" />
      </a>
    </div>)
};



export default SocialMedia;