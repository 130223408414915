import React from "react";
import { NavLink } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import './Dropdown.scss';

const Dropdown = ({ isOpen, onClick, header, children }) => {
  return (
    <div className="dropdown">
      <button
        className="dropdown-header"
        onClick={onClick}
        aria-expanded={isOpen}
        aria-haspopup="true"
      >
        {header}
        <span className={`dropdown-arrow ${isOpen ? "open" : ""}`}></span>
      </button>
      <CSSTransition
        in={isOpen}
        timeout={500}
        classNames="dropdown-content-transition"
        unmountOnExit
      >
        <div className="dropdown-content">
          {children.map(({ route, title }, index) => (
            <NavLink to={route} className="dropdown-item" key={index}>
              {title}
            </NavLink>
          ))}
        </div>
      </CSSTransition>
    </div>
  );
};

export default Dropdown;
