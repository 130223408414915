import React from 'react';
import info from "./home.json";
import EventCard from "../../components/event-card/EventCard";

const EventSection = () => {
  return (
    <div className="event-section">
      <div className="container">
        <h3>Events</h3>
        <h4>Our Events</h4>
        <hr className="underline" />
        <div className="event-body">
          {info.Events.map((data) => (
            <EventCard key={data.id} {...data} />
          ))}
        </div>
        <hr />
        <div className="primary-btn-contain">
          <button className="primary-button">View all Events {">>"}</button>
        </div>
      </div>
    </div>
  );
}

export default EventSection;