import React from 'react';
import './ServiceCard.scss'; import
{ library } from "@fortawesome/fontawesome-svg-core";
import { faClock, faUser, faTag } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

library.add(faClock);
const ServiceCard = ({ image, topic, date, preacher, tags }) => {
  return (
		<a className="card" href="#\">
			<div className="imgBx">
				<img alt="service-img" src={image} />
			</div>
			<div className="textBx">
				<h5>{topic}</h5>
				<h6 className="date">
					<span className="icons">
						<FontAwesomeIcon icon={faClock} />
					</span>
					{date}
				</h6>
				<h6 className="other">
					<span className="icons">
						<FontAwesomeIcon icon={faUser} />
					</span>
					{preacher}
				</h6>
				<h6 className="other">
					<span className="icons">
						<FontAwesomeIcon icon={faTag} />
					</span>
					{tags}
				</h6>
			</div>
		</a>
	);
};

export default ServiceCard;