import React from 'react';
import { faSun, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const EventCard = ({date, name, alias, time, location  }) => {
  return (
		<div className="event-card">
			<div className="wrapper">
				<div className="red">{date}</div>
				<div className="body">
					<div className="name">
						{name}
						<span className="alias">({alias})</span>
					</div>
					<div className="misc">
						<div className="time">
							{" "}
							<FontAwesomeIcon icon={faSun} />
							{" "+time}
						</div>
						<div className="location">
							<FontAwesomeIcon icon={faLocationDot} />
							{" "+location}
						</div>
					</div>
				</div>
			</div>
			<button className="event-button">View</button>
		</div>
	);
}

export default EventCard;