import React from 'react'

const AboutSection = () => {
  return (
    <div className="about-section">
      <div className="image">
        <img
          src="https://images.unsplash.com/photo-1522158637959-30385a09e0da?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          alt="reverend"
        />
      </div>
      <div className="text">
        <h3>About us</h3>
        <h4>Welcome to our Home</h4>
        <p>
          We are a multi-generational religious organization in Towson,
          Maryland, committed to raising bold, faith-filled and positive people
          of impact. We live to ensure that you connect in a special way to God,
          embracing the uniqueness of His grace and love and expressing that
          same love to others in a multi-dimensional way.
        </p>
        <p>
          The Redeemed Evangelical Mission, Baltimore branch launched in 2014.
          Our church is a spirit-led family church located in the heart of
          Baltimore county of Maryland. We are dedicated to the spread and true
          knowledge of the gospel of Jesus Christ. He is the Way, the Truth and
          the Life.
        </p>
        <p>
          As part of the mission, we strive to impact our world by making
          disciples of all nation (Matt 28:19) thereby embracing diversity. We
          are aware that we are all saved by grace, (Eph 2:8) so there is room
          for everyone, no matter who you are and what you have been through.
        </p>
      </div>
    </div>
  );
}

export default AboutSection