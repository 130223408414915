import React from 'react';
import ServiceCard from "../../components/service-card/ServiceCard";
import info from "./home.json";

const ServiceSection = () => {
  return (
    <div className="service-section">
      <h3>Sermons</h3>
      <h4>Our Services</h4>
      <hr />
      <button>See all Sermons {">>"}</button>
      <div className="flex">
        {info.Services.map((data) => (
          <ServiceCard key={data.id} {...data} />
        ))}
      </div>
    </div>
  );
}

export default ServiceSection;