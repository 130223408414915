import React from 'react';
import './Home.scss';
import WelcomeHero from './WelcomeHero';
import ServiceSection from './ServiceSection';
import AboutSection from './AboutSection';
import EventSection from './EventSection';

const Home = () => {
  return (
		<div className="homepage-body">
			<div className='overlay'></div>
			<WelcomeHero />
			<ServiceSection />
			<AboutSection/>
			<EventSection/>
			<div>
				
			</div>
		</div>
	);
}

export default Home;